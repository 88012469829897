@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .z-container {
        @apply xl:max-w-screen-xl lg:w-full lg:pr-32 lg:pl-32 lg:mx-auto ml-8 mr-8;
    }

    .z-section {
        @apply w-full font-bold text-xl flex items-center mt-10 relative;
    }

    .z-section::before {
        content: '';
        display: block;
        width: .25em;
        height: 1.25em;
        background: #666;
        left: -1rem;
        position: absolute;
    }

    .z-side-items {
        width: 200px;
        @apply md:ml-16 sticky top-4;
        height: max-content;
        flex-shrink: 0;
    }

    .paging-btn {
        @apply w-16 text-center h-7 flex justify-center items-center;
        @apply text-gray-900 bg-gray-100;
    }

    .paging-btn.black {
        @apply text-white bg-z-gray;
    }

    .toc-list {
        list-style: none;
        font-size: 80%;
        margin: 0;
        padding: 0;
        overflow: visible;
        white-space: nowrap;
    }

    .toc-list li {
        display: block;
    }

    .toc-list .toc-H3 {
        margin-left: 1rem;
    }

    .toc-list .toc-H4 {
        margin-left: 2rem;
    }

    .toc-list .toc-H5 {
        margin-left: 3rem;
    }
}

@layer utilities {
    .link {
        color: #0072c6;
        border-color: #0072c6;
        @apply border-b;
        transition: all 0.1s;
    }

    .link:hover {
        color: #004185;
        border-color: #004185;
        border-bottom-width: 3px;
        margin-bottom: -2px;
    }
}

.rendered {
    @apply text-z-gray;
}

.heimu {
    color: #252525;
    text-shadow: none;
    background-color: #252525;
    transition: color .13s linear;
    @apply hover:text-white
}

